<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t('_.statusMessage', { teamName, captain: captainHandoverNickname }) }}</p>
    <template v-slot:buttons>
      <button @click="agree">{{ $t(`_.${realTime ? 'realTimeButton' : 'button'}`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'TeamHandoverRequestItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.TEAM_CAPTAIN_HANDOVER_REQUEST',
  props: {
    message: Specific,
  },
  computed: {
    team() {
      return this.message?.data?.team || {};
    },
    user() {
      return this.message?.data?.user || {};
    },
    teamName() {
      return this.team.name;
    },
    tournament() {
      return this.message?.data?.tournament || {};
    },
    captainHandoverNickname() {
      return this.user.captainHandoverNickname;
    },
    captainHandoverUserId() {
      return this.user.captainHandoverUserId;
    },
    forMe() {
      return this.$store.state.auth.userId === this.captainHandoverUserId;
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
  },
  methods: {
    async agree() {
      try {
        const { teamId, isWcgTeam } = this.team;
        const { gameId } = this.team;

        await this.$services.notification.changeCaptainConfirm(teamId);
        this.$toast('_.success');
        if (isWcgTeam) {
          window.location.href = `${process.env.VUE_APP_WCG_URL}/mypage/team`;
        } else {
          this.routePath(`/teams/${gameId}/${teamId}/home`);
        }
        this.$emit('read-item', this.message || {});
      } catch ({ code }) {
        throw ['shared.notification.TEAM_CAPTAIN_HANDOVER_REQUEST.fail', code];
      }
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
